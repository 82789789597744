export function localAnalyticsLogEvent(event, data) {
	let baseurl = window.location.href;
	data.event_name = event;
	if (window.umami) {
		let user = JSON.parse(localStorage.getItem('authUser'));
		data.userId = user ? user.id : null;
		console.log(data);
  	window.umami.trackEvent(event, data, baseurl.replace(/^https?:\/\//, ''));
	}
}

export function localAnalyticsLogPageView() {
	let baseurl = window.location.href;
	if (window.umami) {
		window.umami.trackView(baseurl.replace(/^https?:\/\//, ''));
	}
}

export default {localAnalyticsLogEvent, localAnalyticsLogPageView}