import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { localAnalyticsLogEvent } from '../../../localAnalytics';
import { Helmet } from "react-helmet";
import axios from 'axios';

import './Share.scss';
import '../error/Error.scss';

const api = axios.create();

class Share extends Component {

  constructor(props) {
    super(props);
    this.state = {
      iframeUrl: '',
      loaded: false,
      brandId: false
    }

    this.handleDamIframeMessages = this.handleDamIframeMessages.bind(this);
  }

  // Load brand and page data on page load
  componentDidMount() {
    this.setState({ loadClass: 'fade-in' });
    document.body.classList.add('body-loaded');
    let slug = '', props = this.props.match.params;
    let urlParams = new URLSearchParams(window.location.search);

    if (props.menuslug) {
      slug = props.menuslug;
    }

    if (props.pageslug) {
      slug = props.menuslug+'/'+props.pageslug;
    }

    if (props.subslug) {
      slug = props.menuslug+'/'+props.pageslug+'/'+props.subslug;
    }

    let brand = this.props.brand ? this.props.brand : this.props.match.params.brand;
    let studio = this.props.studio ? this.props.studio : this.props.match.params.studio;

    // API query
    api.get(window.API+brand+(slug || studio ? '?studio='+studio+'&page='+slug : ''), {
      headers: {
        Pragma: 'no-cache',
        Authorization: localStorage.getItem('authToken') || ''
      },
    })
      .then(result => {
        var iframeUrl;
        if (window.EDITOR_URL.includes('http://localhost')) {
          iframeUrl = 'http://localhost:4200/dam/' + result.data.brand.hash + '/share/' + this.props.match.params.hash + '/' + this.props.match.params.asset
        } else {
          iframeUrl = window.EDITOR_URL + '/dam_app/dam/' + result.data.brand.hash + '/share/' + this.props.match.params.hash + '/' + this.props.match.params.asset
        }

        this.setState({
          iframeUrl: iframeUrl,
          loaded: true,
          brandId: result.data.brand.id
        });

        window.addEventListener('message', this.handleDamIframeMessages);
      });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleDamIframeMessages)
  }

  handleDamIframeMessages(event) {
    if (event.data.message == 'externalShareDamOpened') {
      localAnalyticsLogEvent("public_brand_external_share_dam_opened", {
        url: this.state.iframeUrl,
        brandId: this.state.brandId
      });
    }

    if (event.data.message == 'damFileDownload') {
      localAnalyticsLogEvent("public_brand_external_share_dam_file_download", {
        url: this.state.iframeUrl,
        brandId: this.state.brandId,
        items: event.data.items,
        appView: event.data.appView
      });
    }

    if (event.data.message == 'damFileDownloadMultiple') {
      localAnalyticsLogEvent("public_brand_external_share_dam_file_download_multiple", {
        url: this.state.iframeUrl,
        brandId: this.state.brandId,
        items: event.data.items,
        appView: event.data.appView
      });
    }

    if (event.data.message == 'damFileDownloadFromModal') {
      localAnalyticsLogEvent("public_brand_external_share_dam_file_download_from_single_modal", {
        url: this.state.iframeUrl,
        brandId: this.state.brandId,
        items: event.data.items,
        appView: event.data.appView
      });
    }
  }

  // Render page
  render () {
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="og:title" content={this.state.pageTitle} />
          <meta name="og:type" content="website" />
          <body />
        </Helmet>

        {this.state.loaded &&
        <iframe className="dam-opened" allowFullScreen id="dam-iframe" src={this.state.iframeUrl} allow="clipboard-read; clipboard-write"></iframe>
        }
      </div>
    );
  }
}

export default withRouter(Share);
